<template>
  <div class="hello" v-if="$store.state.info.state">
      <el-dialog title="" custom-class="dialogState"  :visible.sync="$store.state.info.state" :show-close="false">
          <el-result  :title="$store.state.info.title"
               :style="resultStyle" :subTitle="$store.state.info.subTitle">
              <!-- <template slot="subTitle" v-html="$store.state.info.subTitle"></template> -->
              <template slot="icon">
        <img v-if="$store.state.info.icon == 'warning'" src="../../assets/img/warning.gif"/>
        <img v-if="$store.state.info.icon == 'success'" src="../../assets/img/succeed.gif"/>
        <img v-if="$store.state.info.icon == 'load'" src="../../assets/img/load.gif"/>
           </template>
              <template slot="extra" v-if="$store.state.info.extra == 'delete'" >
                <el-button :loading="isLocked" type="danger" @click="onSubmit()">确定删除！</el-button>
                  <el-button type="info" @click="cancle()">取 消</el-button>
              </template>
              <template slot="extra" v-if="$store.state.info.extra == 'alter'" >
                <el-button :loading="isLocked" type="primary" @click="onSubmit()">确定修改！</el-button>
                  <el-button type="info" @click="cancle()">取 消</el-button>
              </template>
              <template slot="extra" v-if="$store.state.info.extra == 'create'" >
                <el-button :loading="isLocked" type="primary" @click="onSubmit()">确定生成！</el-button>
                  <el-button type="info" @click="cancle()">取 消</el-button>
              </template>
              <!-- 解析地址 -->
              <template slot="extra" v-if="$store.state.info.extra == 'ok'" >
                <ul style="text-align: left;margin-bottom: 10px;max-height:300px;overflow: auto;width: 100%;">
                   <!-- 外站点的，解析不成功的 -->
                   <li v-for="(value, key) in $store.state.info.data.otherObjects" :key="key">
                    <!-- 展示键 -->
                   <span :style="{fontWeight: '900',color:'red'}">{{ key }}  </span><span :style="{color:'red'}" v-if="value[0]">解析错误</span>
                    <br >
                   <span :style="{marginLeft: '20px',color:'red'}" v-for="(subItem, subIndex) in value" :key="subIndex">
                      {{subItem.endsWith('.') ? subItem.slice(0, -1) : subItem}}
                      <br>
                   </span>
                   <span style="color: red;margin-left: 20px;" v-if="!value[0]">解析失败</span>
                  </li>
                  <!-- 我们站点的 -->
                  <li v-for="(value, key) in $store.state.info.data.targetLastStringObjects" :key="key">
                    <!-- 展示键 -->
                   <span :style="{fontWeight: '900',color:'green'}">{{ key }}  </span>
                    <br >
                   <span style="margin-left: 20px;color: green;" v-for="(subItem, subIndex) in value" :key="subIndex">
                      {{subItem.endsWith('.') ? subItem.slice(0, -1) : subItem}}
                      <br>
                   </span>
                  </li>
                 
                </ul>
                <el-button type="primary" @click="cancle()">OK</el-button>
              </template>
          </el-result>
      </el-dialog>
  </div>
</template>

<script>
import {lineGroupIpDel,lineGroupIpNodesDel,lineGroupIpNodesIsMaster,lineGroupIpNodesStatus,lineGroupIpNodesAdd,dnsDomainsBatchDestroy,lGatchDestroy,batchUpdateNodeStatus,batchUpdateIpStatus,ipNodesBatchDestroy,ipNodesDel,dnsDomainsDel,systemsDel,jumpAddressUpdate, DELDomains, delGroup, delUser, delPermissions, delRoles, batchDestroy, EITDomains, delNodes,delDNS ,batchSetHttps,eitNodes,delJournal,batchJournal,delPort,GetApiToken,ADDDomains,ADDBatchDomains,regionsDel,regionsAllEit} from '_api'

export default {
  name: 'aHint',
  props: ['message'],
  data: function () {
    return {
      state: true, // 批量添加显示
      isLocked: false, // 初始化时按钮未锁定
       // 动态padding值
       padding: '', // 你可以在这里设置默认的padding值
    }
  },
  computed: {
    // 使用计算属性来确保style是响应式的
    resultStyle() {
      return {
        padding: this.$store.state.info.extra != 'ok'?'30px 20px':'0px 20px !important', // 将data中的padding值应用到style中
      };
    },
  },
  watch: {
    message: {
      handler (newVal, oldVal) {
        if (oldVal === false && newVal === true) {
          this.state = newVal
        }
      },
      immediate: true
    }
  },
  methods: {
    // 关闭
    cancle () {
      this.state = false
      this.$store.dispatch('info/actionSTATE')
    },
    //  提交
    onSubmit () {
      if (this.isLocked) return; // 如果按钮已锁定，则不执行任何操作
      this.isLocked = true; // 锁定按钮
      switch (this.$store.state.info.name) {
  case 'DELDomains': // 删除域名
  const pathToFind = '/itemUpdate/'+this.$store.state.info.data.id
        const index = JSON.parse(sessionStorage.getItem('navTab')).findIndex(item => item.path === pathToFind);
        DELDomains(this.$store.state.info.data).then(res => {
          // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, // 控制提示组件显示隐藏
              icon: 'success', // 图标类型 success / warning / info / error
              title: '删除成功！', // 标题
              subTitle: '秒后自动刷新。', // 二级标题
              extra: false, // 底部提交区域显示隐藏
              delay: 1000
            }).then(() => {
              this.$store.commit('menu/DELETE_TABS', index)
              this.$parent.refreshGroups()
              const indicesToDelete = [];
            this.$store.state.menu.navTab.forEach((nav, navIndex) => {
              if (nav.label == '域名配置') {
                indicesToDelete.push(navIndex);
              }
            });

            // 从后向前删除，避免索引变化影响
            indicesToDelete.sort((a, b) => b - a).forEach(index => {
              this.$store.commit('menu/DELETE_TABS', index);
            });
            })
          }
        })
    break;
  case 'delGroup': //删除分组
  delGroup(this.$store.state.info.data).then(res => {
          this.isLocked = false;
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
    break;
  case 'delUser'://删除用户
  if(true){
    const pathToFind = '/UserUpdate/'+this.$store.state.info.data.id
    const index = JSON.parse(sessionStorage.getItem('navTab')).findIndex(item => item.path === pathToFind);
    delUser(this.$store.state.info.data).then(res => {
          this.isLocked = false;
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$store.commit('menu/DELETE_TABS', index)
              this.$parent.refreshGroups()
            })
          }
        })
  }
    break;
    case 'delPermissions'://删除权限
    delPermissions(this.$store.state.info.data).then(res => {
          this.isLocked = false;
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
    break;
    case 'delRoles'://删除角色
    delRoles(this.$store.state.info.data).then(res => {
          this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, 
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
    break;
    case 'delNodes'://删除节点
    delNodes(this.$store.state.info.data).then(res => {
          this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
    break;
    case 'batchDestroy'://批量删除域名
    batchDestroy(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.state.info.data.ids.forEach(element =>{
        const pathToFind = '/itemUpdate/'+ element
        this.$store.commit('menu/DELETE_TABS', JSON.parse(sessionStorage.getItem('navTab')).findIndex(item => item.path === pathToFind))
        });
            this.$store.dispatch('info/actionINFO', {
              state: true, 
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
              const indicesToDelete = [];
            this.$store.state.menu.navTab.forEach((nav, navIndex) => {
              if (nav.label == '域名配置') {
                indicesToDelete.push(navIndex);
              }
            });

            // 从后向前删除，避免索引变化影响
            indicesToDelete.sort((a, b) => b - a).forEach(index => {
              this.$store.commit('menu/DELETE_TABS', index);
            });
            })
          }
        })
    break;
    case 'batchJournal':// 批量删除日志
    batchJournal(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, 
              icon: 'success', 
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
    break;
    case 'delDNS'://删除DNS
    delDNS(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
    break;
    case 'delJournal':// 删除日志
    delJournal(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, 
              icon: 'success', 
              title: '删除成功！', 
              subTitle: '秒后自动刷新。',
              extra: false, 
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
             
            })
          }
        })
    break;
    case 'delPort'://删除端口
 if(true){
  let data =  {...this.$store.state.info.data}
      delete data.type
        delPort(data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              if(this.$store.state.info.data.type == 'admin'){
                 this.$parent.$refs.adminnode1.getPortList()
              }else{
                this.$parent.$refs.skip1.getPortList()
              }
            })
          }
        })
 }
    break;
    case 'EITDomains': // 修改域名及单个修改状态
    if (this.$store.state.info.data.HTTPS) {
          delete this.$store.state.info.data.HTTPS
        } 
    EITDomains(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, 
              icon: 'success',
              title: '修改成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
    break;
    case 'eitNodes'://修改节点
    eitNodes(this.$store.state.info.data).then(res => {
             // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '修改成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
    break;
    case 'batchSetHttps': // 申请证书
    batchSetHttps(this.$store.state.info.data).then(res => {
          // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true, 
              icon: 'success',
              title: '修改成功！', 
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
    break;
    case 'GetApiToken'://个人中心获取token
      // 数据请求完成后解锁按钮
      this.isLocked = false;
        GetApiToken().then((res)=>{
        if (res.data.code === 200) {
          this.$store.dispatch('info/actionINFO', {
                  state: true,
                  icon: 'success',
                  title: '生成成功！',
                  subTitle: '秒后刷新。', 
                  extra: false, 
                  delay: 1000
                }).then(() => {
                  this.$parent.refreshGroups()
                })
        }
      })
    break;
    case 'ADDDomains'://添加域名
    ADDDomains(this.$store.state.info.data).then(res => {
          // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success', 
              title: '添加成功！', 
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.$refs.BatchStore.batchStoreVisible = false // 关闭弹窗
              this.$parent.$refs.BatchStore.$refs.batchAdd1.close() //清除表单数据
              this.$parent.refreshGroups() // 刷新页面
            })
          }
        })
    break;
    case 'ADDBatchDomains'://批量添加域名
    ADDBatchDomains(this.$store.state.info.data).then(res => {
          // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '添加成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.$refs.BatchStore.batchStoreVisible = false// 关闭弹窗
              this.$parent.$refs.BatchStore.$refs.batchAdd2.close()//清除表单数据
              this.$parent.refreshGroups() // 刷新页面
            })
          }
        })
    break;
    case 'regionsDel'://删除地区
    regionsDel(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
    break;
    case 'regionsAllEit'://全部区域屏蔽
    regionsAllEit(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '修改成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
                this.$parent.$refs.hidePath.sourceFormVisible = false
            })
          }
        })
    break;
    case 'jumpAddressUpdate'://批量替换跳转地址
    jumpAddressUpdate(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '修改成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
                this.$parent.$refs.alterRedirect.sourceFormVisible = false
            })
          }
        })
    break;
    case 'systemsDel'://删除设置配置
    systemsDel(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.refreshGroups()
            })
          }
        })
    break;
    case 'dnsDomainsDel'://cname域名删除
    dnsDomainsDel(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.$refs.cname1.onSubmit()
              const indicesToDelete = [];
            this.$store.state.menu.navTab.forEach((nav, navIndex) => {
              if (nav.label == '设置解析') {
                indicesToDelete.push(navIndex);
              }
            });

            // 从后向前删除，避免索引变化影响
            indicesToDelete.sort((a, b) => b - a).forEach(index => {
              this.$store.commit('menu/DELETE_TABS', index);
            });
            })
          }
        })
    break;
    case 'ipNodesDel'://新节点管理 删除节点
    ipNodesDel(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.onSubmit()
            })
          }
        })
    break; 
    case 'ipNodesBatchDestroy'://批量删除新节点
    ipNodesBatchDestroy(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.onSubmit()
            })
          }
        })
    break;
    case 'batchUpdateIpStatus'://批量启用禁用IP
    batchUpdateIpStatus(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '修改成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.onSubmit()
            })
          }
        })
    break;
    case 'batchUpdateNodeStatus'://批量启用禁用节点
    batchUpdateNodeStatus(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '修改成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.onSubmit()
            })
          }
        })
    break;
    case 'lGatchDestroy'://批量删除线路分组

    lGatchDestroy(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.onSubmit()
              const indicesToDelete = [];
            this.$store.state.menu.navTab.forEach((nav, navIndex) => {
              if (this.$store.state.info.data.ids.some(element => element === nav.userId)) {
                indicesToDelete.push(navIndex);
              }
            });

            // 从后向前删除，避免索引变化影响
            indicesToDelete.sort((a, b) => b - a).forEach(index => {
              this.$store.commit('menu/DELETE_TABS', index);
            });
            })
          }
        })
    break;
    case 'dnsDomainsBatchDestroy'://批量删除cname域名
    dnsDomainsBatchDestroy(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.$refs.cname1.onSubmit()
              const indicesToDelete = [];
            this.$store.state.menu.navTab.forEach((nav, navIndex) => {
              if (nav.label == '设置解析') {
                indicesToDelete.push(navIndex);
              }
            });

            // 从后向前删除，避免索引变化影响
            indicesToDelete.sort((a, b) => b - a).forEach(index => {
              this.$store.commit('menu/DELETE_TABS', index);
            });
            })
          }
        })
    break;
    case 'lineGroupIpNodesAdd'://设置解析 IP 批量添加与批量备用
    lineGroupIpNodesAdd(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '添加成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.CateTtems()
            })
          }
        })
    break;
    case 'lineGroupIpNodesStatus'://设置解析 IP 批量启用禁用节点
    lineGroupIpNodesStatus(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '修改成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.CateTtems()
            })
          }
        })
    break;
    case 'lineGroupIpNodesIsMaster'://设置解析 IP 批量修改主备
    lineGroupIpNodesIsMaster(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '修改成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.CateTtems()
            })
          }
        })
    break;
    case 'lineGroupIpNodesDel'://设置解析 IP 批量删除
    lineGroupIpNodesDel(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.CateTtems()
            })
          }
        })
    break;
    case 'lineGroupIpDel'://线路分组 单个删除
    lineGroupIpDel(this.$store.state.info.data).then(res => {
           // 数据请求完成后解锁按钮
        this.isLocked = false;
          // 请求发送成功后弹窗
          if (res.data.code === 200) {
            this.$store.dispatch('info/actionINFO', {
              state: true,
              icon: 'success',
              title: '删除成功！',
              subTitle: '秒后自动刷新。',
              extra: false,
              delay: 1000
            }).then(() => {
              this.$parent.onSubmit()
              let userID = this.$store.state.menu.navTab.indexOf(nav =>  nav.userId ==this.$store.state.info.data.id )
              this.$store.commit('menu/DELETE_TABS', userID)
            })
          }
        })
    break;
  default:
    // 执行默认代码
}

// 
      
      
      
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-result__extra{
  margin-top: 20px !important;
width: 100% !important;
}

::v-deep  .el-dialog{
margin-top: 20vh !important;
}
::v-deep .el-dialog__header{
  padding: 0px !important;
}
</style>
<style lang="scss">

/* 在 CSS 中 */
@media screen and (max-width: 767px) {
  /* 这是手机设备 */
  .dialogState{
  width: 100% !important;

}
}

@media screen and (min-width: 768px) {
  /* 这是电脑 */
  .dialogState{
  width: 500px !important;
}
}
</style>
