export default {
  namespaced: true,
  // vuex存储公共状态的文件
  state: {
    state: false, // 控制提示组件显示隐藏
    alreadyExpired: [], // 即将到期证书
    aboutToExpire: [], // 已经到期证书
  
  },
  actions: {
    async actionEXPIRED ({ commit }, status) {
      return new Promise((resolve, reject) => {
        commit('SET_EXPIRED', status)
          resolve('操作完成')
      })
    },
    async actionEXPIRE ({ commit }, status) {
      return new Promise((resolve, reject) => {
        commit('SET_EXPIRE', status)
          resolve('操作完成')
      })
    },
    async actionPROMPT ({ commit }, status) {
      return new Promise((resolve, reject) => {
        commit('SET_PROMPT', status)
          resolve('操作完成')
      })
    },
    async actionSTATE ({ commit }, status) {
      return new Promise((resolve, reject) => {
        commit('SET_STATE', status)
        resolve('点击取消了')
      })
    }
  },
  mutations: {
    SET_PROMPT (state, status) {
      state.state = status.state
    },
    SET_EXPIRED (state, status) {
      state.alreadyExpired = status.alreadyExpired
    },
    SET_EXPIRE (state, status) {
      state.aboutToExpire  = status.aboutToExpire
    }
  }
}
