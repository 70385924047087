import Vue from 'vue'
import VueRouter from 'vue-router'
import Admin from '_views/Admin'
import { isLogin } from '_utils'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: Admin,
    children: [
      {
        path: '',
        name: '通知公告',
        component: () => import('_views/dashBoard'),
        meta: {
          // 设置角色鉴权，只要对应的角色才能访问对应页面
          roles: '*'
        }
      },
      // {
      //   path: '/dashBoard',
      //   name: '通知公告',
      //   component: () => import('_views/dashBoard'),
      //   meta: {
      //     roles: '*'
      //   }
      // },
      {
        path: '/itemLists',
        name: '域名管理',
        component: () => import('_views/Items'),
        meta: {
          roles: '*'
        }
      },
      {
        path: '/itemUpdate/:domainId',
        name: '域名配置',
        props: true, // 将路由参数作为组件的props传递
        component: () => import('_views/Items/components/itemsUpdate'),
        meta: {
          roles: '*'
        }
      },
      {
        path: '/userInfo',
        name: '账户信息',
        component: () => import('_views/userInfo'),
        meta: {
          roles: '*'
        }
      },
      {
        path: '/groupLists',
        name: '分组管理',
        component: () => import('_views/Cate'),
        meta: {
          roles: '*'
        }
      },
      {
        path: '/district',
        name: '区域管理',
        component: () => import('_views/district'),
        meta: {
          roles: ['superAdmin']
        }
      },
      {
        path: '/gettoken',
        name: 'ApiToken',
        component: () => import('_views/apiToken'),
        meta: {
          roles: '*'
        }
      },
      {
        path: '/install',
        name: '系统配置',
        component: () => import('_views/install'),
        meta: {
          roles: '*'
        }
      },
      {
        path: '/SetUser',
        name: '用户管理',
        component: () => import('_views/SetUser'),
        meta: {
          roles: ['admin', 'superAdmin']
        }
      },
      {
        path: '/UserAdd',
        name: '新增用户',
        component: () => import('_views/SetUser/components/UserAdd'),
        meta: {
          roles: '*'
        }
      },
      {
        path: '/UserUpdate/:userId',
        name: '修改用户',
        props: true, // 将路由参数作为组件的props传递
        component: () => import('_views/SetUser/components/UserUpdate'),
        meta: {
          roles: '*'
        }
      },
      {
        path: '/SetTing',
        name: '权限管理',
        component: () => import('_views/SetTing'),
        meta: {
          roles: ['superAdmin']
        }
      },
      {
        path: '/SetRole',
        name: '角色管理',
        component: () => import('_views/SetRole'),
        meta: {
          roles: ['superAdmin']
        }
      },
      {
        path: '/nodelist',
        name: '节点管理',
        component: () => import('_views/nodelist'),
        meta: {
          roles: ['superAdmin']
        }
      },
      {
        path: '/certificate',
        name: 'DNS管理',
        component: () => import('_views/certificate'),
        meta: {
          roles: '*'
        }
      },
      {
        path: '/history',
        name: '操作日志',
        component: () => import('_views/history'),
        meta: {
          roles: '*'
        }
      },
      {
        path: '/journal',
        name: '日志管理',
        component: () => import('_views/journal'),
        meta: {
          roles: ['superAdmin']
        }
      },
      
      {
        path: '/backups',
        name: '域名备份',
        component: () => import('_views/backups'),
        meta: {
          roles: ['superAdmin']
        }
      },
      {
        path: '/ip',
        name: 'IP管理',
        component: () => import('_views/ip'),
        meta: {
          roles: ['superAdmin']
        }
      },{
        path: '/monitoring',
        name: '监控',
        component: () => import('_views/monitoring'),
        meta: {
          roles: ['superAdmin']
        }
      },
      {
        path: '/NodeControl',
        name: '新节点管理',
        component: () => import('_views/NodeControl'),
        meta: {
          roles: ['superAdmin']
        }
      },
      {
        path: '/LineGroup',
        name: '线路分组',
        component: () => import('_views/LineGroup'),
        meta: {
          roles: ['superAdmin']
        }
      },
      {
        path: '/DNSSetup',
        name: 'DNS设置',
        component: () => import('_views/DNSSetup'),
        meta: {
          roles: ['superAdmin']
        }
      },
      {
        path: '/Setup/:userId',
        name: '设置解析',
        component: () => import('_views/LineGroup/components/Setup'),
        meta: {
          roles: ['superAdmin']
        }
      }
    
      
    ]
  },
  {
    path: '/login',
    component: () => import('_views/Login'),
    meta: {
      roles: '*'
    }
  },
  {
    path: '/noAuth',
    component: () => import('_views/noPermission'),
    meta: {
      roles: '*'
    }
  },
  {
    path: '/404',
    component: () => import('_views/NotFound'),
    meta: {
      roles: '*'
    }
  },
  {
    path: '*',
    component: () => import('_views/NotFound'),
    meta: {
      roles: '*'
    }
  }
]

const router = new VueRouter({
  // history
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path !== '/login') {
    if (isLogin()) {
      document.title = to.name + '-' + '域名转发管理后台'
      // 设置全局标题
      // 登陆成功后判断用户访问的页面是否符合权限
      if (to.meta.roles === '*') {
        next()
      } else {
        const role = localStorage.getItem('role') || ''
        // 查找state中的navs的roles中有缓存中的角色名，让有角色名的路由显示
        if (to.meta.roles.includes(role)) {
          next()
        } else {
          next('/noAuth')
        }
      }
    } else {
      document.title = '域名转发管理后台'
      next('/login')
    }
  } else {
    next()
  }
})
export default router