<template>
  <div style="position: relative;">
    <el-progress v-if="$store.state.user.loading" :text-inside="true" :percentage="$store.state.user.percentage" :stroke-width="3" />

    <el-container class="container" style="position: relative;">

      <el-aside  v-if="windowWidth > 992" :width="$store.state.menu.meunCollapse ? 'auto' : '180px'">
        <component-aside/>
      </el-aside>
      <el-drawer
     v-else
     :size="isMobile?'40%':'30%'"
      :with-header="false"
  :visible.sync="$store.state.menu.menuDrawer"
  direction="ltr">
  <el-aside width="auto" style="height: 100%;">
        <!-- 外部引入 -->
        <component-aside/>
      </el-aside>
    </el-drawer>
      <!-- 侧边栏 -->
      <!-- 主体区域：头部和主体部分 -->
      <el-container class="main">
        <!-- 头部区域 -->
        <el-header>
        <!-- 外部引入 -->
          <component-header ref="componentHeader"/>
        </el-header>
        <!-- 历史记录区域 -->
        <componentHeader-nav></componentHeader-nav>
        <!-- 主体区域 -->

        <el-main>
            <transition
      name="fade"
      mode="out-in">
      <keep-alive>
              <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
          </transition>
            <transition
      name="fade"
      mode="out-in">
      <router-view  v-if="!$route.meta.keepAlive"></router-view>  </transition>
          </el-main>

      </el-container>
    </el-container>

  </div>
</template>
<script>

import ComponentAside from './components/ComponentAside.vue'
import ComponentHeader from './components/ComponentHeader.vue'
import ComponentHeaderNav from './components/ComponentHeaderNav.vue'
export default {
  name: 'ADmin',
  components: {
    ComponentAside,
    ComponentHeader,
    ComponentHeaderNav
  },
  data () {
    return {
      windowWidth: window.innerWidth,
      isMobile: false // 是不是手机端
    }
  },
  methods: {
    // 实时监控浏览器宽度
    handleResize () {
      this.windowWidth = window.innerWidth
    },
    // 判断是否是手机端
    checkDevice () {
      const userAgent = navigator.userAgent
      // 常见的手机设备浏览器的userAgent中包含的字符串
      const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i
      this.isMobile = mobileRegex.test(userAgent)
    }
  },
  created () {
    this.checkDevice()
    window.addEventListener('resize', this.handleResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>
<style lang="scss" scoped>

.details{
   opacity: 0; /* 初始状态为透明 */
  animation: fadeIn 0.5s ease-in-out forwards; /* 使用 CSS 动画实现淡入效果 */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-100%); /* 从左侧位移进入 */
  }
  to {
    opacity: 1; /* 淡入至完全不透明 */
    transform: translateX(0); /* 手机到初始位置 */
  }
}
// 整个外部div
.el-container {
  height: 100vh;
}
// 侧边栏
.el-aside {
  background-color: #18212c;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
}
// 主体
.el-main {
  background-color: #f0f2f5;
  flex-basis:0 ; // 不让历史记录压缩
}
// 头部
.el-header {
  background-color: #fff;
  height: 100px;
}

::v-deep .el-form-item__label{
  font-weight: 600;
}
::v-deep .el-table--border{
  width: 97% !important;
  margin: 0 auto;
}
// 设置表格线条加深
::v-deep .el-table  td.el-table__cell,::v-deep  .el-table th.el-table__cell.is-leaf{
  border-bottom: 1px solid #3d3d3d;
}
::v-deep .el-table--border .el-table__cell,::v-deep .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
  border-right: 1px solid #3d3d3d;
}
::v-deep .el-table--border,::v-deep  .el-table--group{
  border: 1px solid #3d3d3d;
}
::v-deep .el-table thead{
  color: #000;
  font-weight: 800;
  font-size: 15px;
}
// 表单线条加粗
::v-deep .el-input__inner,::v-deep .el-textarea__inner {
border-color: #9d9d9d;
}
// 文本域和提示背景颜色清楚
::v-deep .el-alert--warning.is-light{
  background-color: #ffffff00;
}
::v-deep .el-textarea__inner{
  background-color: #ffffff00;
}
::v-deep .el-input__inner ,::v-deep .el-date-editor .el-range-input{
background-color: #ffffff00;
}
::v-deep .el-table--border::after,::v-deep  .el-table--group::after,::v-deep  .el-table,::v-deep .el-table tr,::v-deep .el-table th.el-table__cell {
background-color: #ffffff00;
}
// 出厂动画
/* 添加 CSS 动画 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s, transform 0.3s; /* 同时过渡opacity和transform */
}
.fade-enter /* 进入阶段开始的状态 */ {
  opacity: 0;
  transform: translateX(-100%); /* 从左边开始，向左手机自身宽度 */
}
.fade-enter-to /* 进入阶段结束的状态 */ {
  opacity: 1;
  transform: translateX(0); /* 手机到正常位置 */
}
.fade-leave /* 离开阶段开始的状态 */ {
  opacity: 1;
  transform: translateX(0); /* 初始状态为正常位置 */
}
.fade-leave-to /* 离开阶段结束的状态 */ {
  opacity: 0;
  transform: translateX(100%); /* 向右边手机自身宽度，实现淡出效果 */
}


</style>
<style  lang="scss">
 .el-progress{
  line-height: 0 !important;
  z-index: 9999 ;
}
 .el-progress-bar {
  padding-right: 0px !important;
  margin-right: 0px !important;
}
.el-progress__text{
  display: none !important;
}

</style>
